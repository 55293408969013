@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

@font-face {
    font-family: 'Satoshi';
    src: url('/public/fonts/Satoshi-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
 


  body{
    background: #ffffff;
    font-family: "Inter", sans-serif;
  }
  
  a{
    
    font-family: "Satoshi";
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-family: "Inter", sans-serif;
  
  }
  
  p{
    font-family: "Inter", sans-serif;
  }
  
  .work-anibe{
    font-family: "Satoshi";
  }
  
  
  btn:is(input[type="checkbox"]:checked), .btn:is(input[type="radio"]:checked) {
    --tw-border-opacity: 1;
    border-color: #0186ef !important;
    --tw-bg-opacity: 1;
    background-color: #0186ef !important;
    --tw-text-opacity: 1;
    color: var(--fallback-pc, oklch(var(--pc) / var(--tw-text-opacity)));
  }


  .swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
 
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  

  .animated-svg .pulse {
    animation: pulseAnimation 2s infinite ease-in-out;
  }
  
  @keyframes pulseAnimation {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.2);
      opacity: 0.7;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  

  .subscribe {
    background: url(/public/images/subcsribe-bg.png);
    background-size: cover;
  }

  .steps .step {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-template-columns: auto;
    grid-template-rows: repeat(2, minmax(0, 1fr));
    grid-template-rows: 40px 1fr;
    place-items: center;
    text-align: center;
    min-width: 4rem /* 64px */;
}
.steps .step:before {
    top: 0px;
    grid-column-start: 1;
    grid-row-start: 1;
    height: 0.5rem /* 8px */;
    width: 100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    --tw-bg-opacity: 1;
    background-color: #5124d1 !important;
    --tw-text-opacity: 1;
    color: var(--fallback-bc,oklch(var(--bc)/var(--tw-text-opacity)));
    content: "";
    margin-inline-start: -100%;
}
.steps .step:after {
    content: counter(step);
    counter-increment: step;
    z-index: 1;
    position: relative;
    grid-column-start: 1;
    grid-row-start: 1;
    display: grid;
    height: 2rem /* 32px */;
    width: 2rem /* 32px */;
    place-items: center;
    place-self: center;
    border-radius: 9999px;
    --tw-bg-opacity: 1;
    background-color: #5124d1 !important;
    --tw-text-opacity: 1;
    color: #ffffff !important;
}
.steps .step:first-child:before {
    content: none;
}
.steps .step[data-content]:after {
    content: attr(data-content);
}
.steps-horizontal .step {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-template-rows: repeat(2, minmax(0, 1fr));
    place-items: center;
    text-align: center;
}
.steps-vertical .step {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: repeat(1, minmax(0, 1fr));
}
.steps-horizontal .step {
    grid-template-rows: 40px 1fr;
    grid-template-columns: auto;
    min-width: 4rem /* 64px */;
}
.steps-horizontal .step:before {
    height: 0.5rem /* 8px */;
    width: 100%;
    --tw-translate-x: 0px;
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    content: "";
    margin-inline-start: -100%;
}
.steps-horizontal .step:where([dir="rtl"], [dir="rtl"] *):before {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.steps-vertical .step {
    gap: 0.5rem /* 8px */;
    grid-template-columns: 40px 1fr;
    grid-template-rows: auto;
    min-height: 4rem /* 64px */;
    justify-items: start;
}
.steps-vertical .step:before {
    height: 100%;
    width: 0.5rem /* 8px */;
    --tw-translate-x: -50%;
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    margin-inline-start: 50%;
}
.steps-vertical .step:where([dir="rtl"], [dir="rtl"] *):before {
    --tw-translate-x: 50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}